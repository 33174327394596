.appbutton {
  background-color: #0bc821 !important;
  font-weight: bold;
}
.bannerImage {
  background-image: url("../banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-font-style {
  font-size: "45px";
  color: "#fff";
  font-weight: "700";
  line-height: "45px";
}

.reusable-image-classes {
  height: "290px";
  background-repeat: no-repeat;
}
.hwbutton {
  border: none !important;
  width: "250px" !important;
}

.card-image img {
    border-radius: 50%; /* makes the image round */
    border: 4px solid #fff; /* white border around the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow */
  }
  
  .card {
    border-radius: 15px; /* rounded corners for the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow */
  }
  
  .card-title {
    font-weight: 700; /* for bold text */
  }
  
  /* You may need to adjust the padding and margins as necessary. */

  /* .section1{
    max-width: 100% !important;
  }
  
  .s1-strip{
	background:url("../s1bg.jpg") center top no-repeat;
	overflow:hidden;
	float:left;
	width:100%;
	padding:80px 0;
	text-align:center;
	position:relative;
	background-size:cover;
	}
.s1-stp-blt1{
	position:absolute;
	top:-200px;
	left:-200px;
    background-position: relative;
	}	

.s1-stp-blt2{
	position:absolute;
	right:-100px;
	bottom:-219px;
	}
.s1-stpmiddle{
	display:inline-block;
	vertical-align:middle;
	max-width:897px;
	width:100%;
	border-radius:8px;
	position:relative;
	box-shadow:0 15px 12px 7px rgba(0,0,0,0.4);
	-webkit-box-shadow:0 15px 12px 7px rgba(0,0,0,0.4);
	}	
.s1-stpmiddle-inr{
	float:left;
	width:100%;
	text-align:center;
	background: rgba(0,0,0,0.8);
	padding:50px 70px;
	box-shadow: 0px 0 0 10px rgba(96,98,97,0.8);
	-webkit-box-shadow: 0px 0 0 10px rgba(96,98,97,0.8);
	border-radius:8px;
	}
.stp-logo{
	display:block;
	margin:0 auto;
	max-width:100%;
	}
ul.s1-stp-list{
	float:left;
	width:100%;
	margin:40px 0 0 0;
	}
ul.s1-stp-list li{
	float: left;
    width: 44%;
    background: url(../icon.png) left top no-repeat;
    background-size: 32px;
    font-size: 23px;
    line-height: 34px;
    text-align: left;
    color: #fff;
    font-weight: 500;
    padding: 0 0 0 48px;
    margin:25px 0 0 0;
	}					
ul.s1-stp-list li:nth-child(even){ float:right; width:55%;}  */