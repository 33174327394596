.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p {
  font-size: 20px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Poppins';
}

.lm-v {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

a {
  color: rgb(26, 115, 232)!important;
  font-weight: bolder;
}

.footer-content p {
  font-size: 14px !important;
}

.btn-primary {
  padding-left: 40px !important;
  padding-right: 40px !important;
  font-size: 26px !important;
}

.s1-strip {
  background: url(Assets/s1bg.jpg) center top no-repeat;
  overflow: hidden;
  float: left;
  width: 100%;
  padding: 80px 0;
  text-align: center;
  position: relative;
  background-size: cover;
}

.stp-logo {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  object-fit: contain;
}

ul.s1-stp-list {
  float: left;
  width: 100%;
  margin: 40px 0 0 0;
}

ul.s1-stp-list li {
  float: left;
  width: 44%;
  background: url(Assets/s1-stp-tk.png) left top no-repeat;
  background-size: 32px;
  font-size: 23px;
  line-height: 34px;
  text-align: left;
  color: #fff;
  font-weight: 500;
  padding: 0 0 0 48px;
  margin: 25px 0 0 0;
}

.s1-stp-list-item {
  font-size: 23px;
  line-height: 34px;
  text-align: left;
  color: #fff;
  font-weight: 500;
}

li {
  list-style: none;
}

.s1-stp-blt1 {
  position: absolute;
  top: -200px;
  left: -200px;
}

.s1-stp-blt2 {
  position: absolute;
  right: -100px;
  bottom: -219px;
}

.s1-stpmiddle {
  display: inline-block;
  vertical-align: middle;
  max-width: 897px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 15px 12px 7px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 15px 12px 7px rgba(0, 0, 0, 0.4);
}

.s1-stpmiddle-inr {
  float: left;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 50px 10px;
  box-shadow: 0px 0 0 10px rgba(96, 98, 97, 0.8);
  -webkit-box-shadow: 0px 0 0 10px rgba(96, 98, 97, 0.8);
  border-radius: 8px;
}

@media only screen and (max-width: 767px) {
  .s1-strip {
    padding: 50px 0;
  }

  .s1-stpmiddle-inr {
    padding: 25px 15px;
  }

  ul.s1-stp-list {
    margin: 21px 0 0 0;
  }

  .stp-logo {
    width: 239px;
  }

  .s1-stpmiddle {
    width: 95%;
  }

  .s1-stp-blt1 {
    top: -100px;
    width: 205px;
    left: 0;
  }

  .s1-stp-blt2 {
    bottom: -100px;
    width: 205px;
    right: 0;
  }
}
